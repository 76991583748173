/* Globals */

body {
  background: url('../images/background2.jpg') #333 top left repeat;
  margin: 0;
}
.App {
  font-family: 'Roboto', sans-serif;
}
img { max-width: 100%; }

/* navbar */
.navbar {
  text-align: center;
}

.navbar-brand {
  font-size: 1.3rem;
  white-space: normal;
}

.navbar-brand img {
  max-width: 45px;
  margin-right: 15px;
}

.navbar-right {
  display: block;
}
@media (min-width: 1024px){
  .navbar {
    text-align: left;
  }
  .navbar-brand {
    font-size: 2rem;
  }
  .navbar-right {
    text-align: right;
    float: right;
    display: inline-block;
  }
}

/* Custom Icon */
.muidocs-icon-custom-github:before {
    content: "\e625";
}

/* Work Section*/
.work-item {
  margin: 40px 0;
  background-color: #f7f7f7;
  padding: 20px;
}

.work-item h2 {
  margin-top: 10px;
}

.work-item .slick-slide img {
  max-height: 400px;
  display: inline-block;
}
.work-item .slick-prev:before,
.work-item .slick-next:before {
  color: black;
}

.work-item .slick-next { right: 0; }
.work-item .slick-prev { left: 0; }

.work-item .slick-next,
.work-item .slick-prev{ z-index: 5; }

.work-image {
  text-align: center;
  margin: 10px 0;
  float: right;
}

@media( min-width: 992px){
  .work-list .row:nth-child(even) .work-details{
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
}
